/* Provide sufficient contrast against white background */
body {
    font-family: 'Lato-Regular';
    background-color: #f3f5f8;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #212529;
    font-family: Lato;
    font-size: 12px;;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #212529;
    font-family: Lato;
    font-size: 12px;;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #212529;
    font-family: Lato;
    font-size: 12px;;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #212529;
    font-family: Lato;
    font-size: 12px;;
  }


@media (min-width: 1200px) {
    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 50px;
        width: 1170px;
    }
    .form-container {
        margin-top: 30px;
        background-color: white;
        padding-left: 80px;
        padding-top: 50px;
        padding-bottom: 100px;
    }
}

@media (min-width: 992px) {
    .container{
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 50px;
        width: 970px;
    }
    .form-container {
        margin-top: 30px;
        background-color: white;
        padding-left: 80px;
        padding-top: 50px;
        padding-bottom: 100px;
    }
}

@media (min-width: 768px) {
    .container{
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 50px;
        width: 950px;
    }

    .form-container {
        margin-top: 30px;
        background-color: white;
        padding-left: 80px;
        padding-top: 50px;
        padding-bottom: 0px;
    }
}

@media (min-width: 576px)  {
    .container{
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 50px;
        width: 950px;
    }

    .form-container {
        margin-top: 20px;
        background-color: white;
        padding-left: 50px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@media (min-width: 375 px) {
    .container{
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 50px;
        width: 950px;
    }

    .form-container {
        margin-top: 20px;
        background-color: white;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 50px;
    }
}

form .form-group .form-control {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    color: #000;
    padding: 0;
    text-transform: uppercase;
    border-bottom: 1px solid #e53d3d;
    font-family: 'Lato-Regular';
    font-size: 12px;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
}

.text-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}



.form-label {
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;;
}

.form-title {
    font-family: Lato;
    font-weight: 700;
    font-size: 20px;;
}

.form-label-bold {
    margin-top:10px;
    font-family: Lato;
    font-weight: 700;
    font-size: 15px;;
    color: #212529;
    height: 24px;
}

.form-button {
    font-family: Lato;
    font-weight: 550;
    font-size: 14px;
    border:1px solid #000000;
    height:38px;
    color:#d40a0b;
}

.valid-tip {
    color: #f00;
    font-size: .8em;
    font-weight: normal;
    display: block;  
}


.Nvoke-button {
    color: #e53d3d !important;
    text-transform: uppercase !important;
    border-radius: 0px;
    border-color: #000 !important;
    width: 100% !important;
    border-width: 1px !important;
    height: 40px !important;
    vertical-align: middle !important;
    background-color:  #fff; 
}

.Nvoke-button:hover {
background-color: #d90707 !important;
    color: #fff !important;
    border-color: #d90707 !important;
    text-decoration: none !important;
    outline: none; 
}


.Nvoke-link {
    color: #e53d3d !important;
    text-transform: uppercase !important;    
    border: none !important;
    width: 100% !important;
    cursor: pointer !important;
}

.Nvoke-link:hover {
    cursor: pointer !important;
}

.Nvoke-counter {
    color: #fff; 
    font-weight: 550;
    background-color: #185CCE; 
    height: 40px;
    border-radius: 20px;
    padding: 0.5em;
    vertical-align: middle !important;
    text-align: center;
    width: 100% !important;
    padding-bottom: 1px;
}


.nvoke-img-height {
    height:66px
}

.nvoke-logo-future-padding {
padding-bottom: 4px;
}

.no-spots-remaining {
    color: red;
    font-size:12px;
}